.post{
    box-sizing: border-box;
    margin-top: 150px;
    padding: 2%;
    max-width: 90%;
    width: 1300px;
    flex-direction: column;
    display: flex;
    padding-bottom: 2%;
    background-color: #ffffff;
    border-radius: 10px;
}



#voltarPagina{
    cursor: pointer;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    font-family: 'open sans';
    font-weight: 500;
    color: #26404F;
    width: 150px;
    font-size: 1.6rem;
    padding: 7px;
    background-color: #e9e9e9;
}



.header_post{
    width: 100%;
    flex-direction: column;
    padding-bottom: 2%;
    border-bottom: 1px solid #c7c7c752;
    display: flex;
    margin-bottom: 50px;
}


.header_post h1{
    font-size: 5rem;
    color: #26404F;
}


.data_autor{
    margin-top: -20px;
    display: flex;
}

.data_autor p {
    font-weight: lighter;
    margin: 0 0px 0 2px;
    color: #9e9e9e;
    font-size: 2.2rem;
}

.conteudo_post{
    max-width: 90%;
    font-size: 2rem;
    color: #26404F;
    margin-top: 10px;
}

.conteudo_post a {
  word-break: break-all;
}

.conteudo_post h1{
    font-size:  5rem;
  }

.conteudo_post h2{
    font-size:  4rem;
  }

.conteudo_post h3{
    font-size:  3.5rem;
  }

.conteudo_post p{
    font-size:  2.5rem;
  }

.conteudo_post img{
    max-width: 90%;
  }


.header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 460px;
    background: rgb(0 0 0 / 81%);

}

.header::after{
    position: absolute;
    z-index: -6;
    content: "";
    top: 0;
    filter: blur(2px);
    left: 0;
    width: inherit;
    height: inherit;
    background-image: url("../imgs/bg-informatica.jpeg");
    background-position: center;
    background-size: cover;
}


.header h1{
    color: #fff;
    margin: 0;
    font-size: 5rem;
}


.header p {
    text-align: center;
    font-weight: lighter;
    color: #fff;
    font-size: 3.3rem;
}


@media (max-width: 550px) {
    .header h1{
        color: #fff;
        margin: 0;
        font-size: 4rem;
    }
    
    .header p {
        font-size:3rem;
    }
    /* responsividade dos textos */
  }
  



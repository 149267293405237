.nav_links {
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  display: flex;
  padding: 2%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
  height: 100px;
}

.nav_links > img {
  height: 4rem;
}

.links {
  height: auto;
  transition: all 210ms;
}

.links ul {
  list-style: none;
  display: flex;
}

.links ul li a {
  text-decoration: none;
  font-weight: 500;
  color: aliceblue;
  font-size: 1.8rem;
  margin: 0 0px 0px 20px;
}

.links ul li a:hover {
  color: #cfcfcf;
}

.links_scroll {
  color: #364753 !important;
}

.links_scroll:hover {
  color: #b1b1b1 !important;
}

#btnMobile,
#fecharMobile {
  background-color: transparent;
  border: none;
  display: none;
}

#fecharMobile {
  font-size: 1.8rem;
}

/* responsividade  */

@media (max-width: 1000px) {
  .links {
    visibility: hidden;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    border-radius: 0;
    transform: translateX(100%);
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }

  .links ul li a {
    color: #364753;
  }

  .links ul {
    display: none;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    padding: 0;
    flex-direction: column;
  }

  .links ul li {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  #btnMobile {
    display: block;
  }

  #fecharMobile {
    display: block;
  }

  #nav_.active .links {
    transition: all 150ms;
    padding: 10px;
    transform: none;
    visibility: visible;
    display: flex;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    background-color: #d8d8d8;
    margin-left: auto;
    align-items: center;
    flex-direction: row-reverse;
  }

  #nav_.active .links > ul {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #nav_.active .links ul li a {
    margin: 0;
    font-size: 2.5rem;
  }

  #fecharMobile {
    margin-top: 15px;
    align-self: flex-start;
  }
}

@media (max-width:650px) {
  #nav_.active .links ul li a {
    font-size: 2.6rem;
  }
}

@media (max-width:550px) {
  #nav_.active .links ul li a {
    font-size: 2.7rem;
  }
}

@media (max-width:450px) {
  #nav_.active .links ul li a {
    font-size: 3.4rem;
  }
}

@media (max-width:350px) {
  #nav_.active .links ul li a {
    font-size: 3.6rem;
  }
}

